const isServer = typeof window === 'undefined';

let services = {};

export const setServices = (_services) => {
	services = _services;
};

if (!isServer) {
	services = require('../../public/services');
}

const Services = () => {
	return services;
};

export default Services;
