import fetch from 'isomorphic-unfetch';

class Transaction {
	async get(id, bankId) {
		const response = await fetch('/api/transaction/' + id + '/banks/' + bankId);
		return response.json();
	}
}

module.exports = Transaction;
