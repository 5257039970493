import App, { Container } from 'next/app';
import React from 'react';
import { setServices } from '../lib/shared/services';
import UserContext from '../components/UserContext';
import Login from './login';
import CustomError from '../pages/_error';
import '../styles/index.css';
import ChangePassword from '../components/ChangePassword';
import ValidateOtp from '../components/ValidateOtp';
import ToastBar from '../components/ToastBar';
import { SSEProvider } from 'react-hooks-sse';

import { init } from '@sentry/nextjs';
import getSentryConfig from '../utils/sentry';

init(getSentryConfig());

const isServer = typeof window === 'undefined';

class MyApp extends App {
	constructor() {
		super();

		this.state = {
			user: null
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.user) {
			return {
				...state,
				user: props.user
			};
		}

		return state;
	}

	async signInBawag() {
		await fetch('/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: 'h.juergen@bawag.at',
				password: 'test123'
			})
		});

		location.reload();
	}

	async signInBankAustria() {
		await fetch('/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: 'maria.wagner@bankaustria.at',
				password: 'insecurePassword'
			})
		});

		location.reload();
	}

	async signInDemoBank() {
		await fetch('/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: 'maria.wagner@demobank.at',
				password: 'insecurePassword'
			})
		});

		location.reload();
	}

	async signIn({ username, password }) {
		const result = await fetch('/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ username, password })
		});

		if (result.status !== 200) {
			return false;
		}

		location.reload();

		return true;
	}

	async signOut() {
		await fetch('/logout', {
			method: 'POST'
		});

		location.reload();
	}

	static async getInitialProps({ Component, ctx }) {
		let pageProps = {};

		let user;
		let countsByStatus = {};
		let base;

		try {
			if (isServer) {
				setServices({
					vorgang: ctx.res.locals.services.vorgang,
					bank: ctx.res.locals.services.bank,
					transaction: ctx.res.locals.services.transaction,
					kondition: ctx.res.locals.services.kondition,
					user: ctx.res.locals.services.user,
					settings: ctx.res.locals.services.settings
				});

				countsByStatus = ctx.req.session.user
					? await ctx.res.locals.services.vorgang.countByStatusGroup(ctx.req.session.user.bankId)
					: {
							inbox: 0,
							inProgress: 0,
							finished: 0
					  };

				user = ctx.req.session.user;
			}

			base = {
				query: ctx.query,
				countsByStatus,
				user
			};

			if (
				Component.getInitialProps &&
				user &&
				!user.isTemporaryPassword &&
				user.otpValid === true &&
				isServer
			) {
				pageProps = await Component.getInitialProps(base);
			} else if (Component.getInitialProps && !isServer) {
				pageProps = await Component.getInitialProps(base);
			}
		} catch (e) {
			return {
				err: e
			};
		}

		return {
			pageProps,
			...base
		};
	}

	sseWrapper(bankId, children) {
		if (bankId) {
			return (
				<SSEProvider endpoint={`/next/sse?bankId=${bankId}`}>
					<ToastBar />
					{children}
				</SSEProvider>
			);
		} else {
			return children;
		}
	}

	content() {
		const { Component, pageProps } = this.props;

		return (
			<>
				{this.state.user &&
					this.state.user.isTemporaryPassword !== true &&
					this.state.user.otpValid === true && <Component {...pageProps} />}
				{this.state.user && this.state.user.otpValid !== true && <ValidateOtp />}
				{this.state.user &&
					this.state.user.otpValid === true &&
					this.state.user.isTemporaryPassword === true && <ChangePassword />}
				{!this.state.user && <Login />}
			</>
		);
	}

	render() {
		if (this.props?.error || this.props?.err) {
			return (
				<CustomError
					statusCode={this.props?.error?.statusCode || this.props?.err?.statusCode}
					err={this.props.error || this.props.err}
				/>
			);
		}

		return (
			<UserContext.Provider
				value={{
					user: this.state.user,
					signIn: this.signIn.bind(this),
					signOut: this.signOut.bind(this),
					signInBawag: this.signInBawag.bind(this),
					signInBankAustria: this.signInBankAustria.bind(this),
					signInDemoBank: this.signInDemoBank.bind(this)
				}}
			>
				{this.sseWrapper(this.state?.user?.bankId, this.content())}
			</UserContext.Provider>
		);
	}
}

export default MyApp;
