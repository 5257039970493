import React, { useContext, useState } from 'react';
import UserContext from '../components/UserContext';
import styles from '../styles/basicVariables.module.css';
export default function Login() {
	const { signIn, signInDemoBank } = useContext(UserContext);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);

	const submitHandler = async (e) => {
		e.preventDefault();
		const success = await signIn({ username, password });

		if (!success) {
			setErrorMessage('Die Benutzerdaten sind ungültig');
		}
	};

	return (
		<div className={styles.loginForm}>
			<img src='/static/svgs/logo-durchblicker.svg' />
			{errorMessage && <div className={styles.textError}>{errorMessage}</div>}
			<div>
				<form onSubmit={submitHandler}>
					<label htmlFor='username'>Benutzer:</label>
					<input
						id='username'
						type='text'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						autoFocus
					/>
					<label htmlFor='username'>Passwort:</label>
					<input
						id='password'
						type='password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<button className={styles.btn}>Anmelden</button>
				</form>
				{/* <button className='btn' onClick={e => signIn({ username: 'susanne.reiner@easybank.at', password: 'insecurePassword' })}>Login EasyBank</button> */}
				{/* <button className='btn' onClick={signInBawag}>Login Bawag</button> */}
				{/* <button className='btn' onClick={signInDemoBank}>Demo: Login Demo Bank</button> */}
			</div>
		</div>
	);
}
