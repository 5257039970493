import React from 'react';
import { withScope, captureException } from '@sentry/nextjs';
import ErrorPage from '../components/ErrorPage';
import NextErrorComponent from 'next/error';

const CustomError = ({ statusCode, hasGetInitialPropsRun, err }) => {
	if (!hasGetInitialPropsRun && err) {
		console.log('capture error: ', err);
		// getInitialProps is not called in case of
		// https://github.com/zeit/next.js/issues/8592. As a workaround, we pass
		// err via _app.js so it can be captured
		if (err instanceof Error) {
			captureException(err);
		} else {
			captureException(new Error(JSON.stringify(err)));
		}
	}

	return (
		<div>
			<ErrorPage statusCode={statusCode} />
		</div>
	);
};

CustomError.getInitialProps = async ({ req, res, err, asPath, pathname }) => {
	const errorInitialProps = await NextErrorComponent.getInitialProps({ res, err });
	errorInitialProps.hasGetInitialPropsRun = true;

	return new Promise((resolve, reject) => {
		withScope((scope) => {
			// Workaround for https://github.com/zeit/next.js/issues/8592, mark when
			// getInitialProps has run

			scope.setExtra('pathname', pathname);
			scope.setExtra('asPath', asPath);

			if (req) {
				scope.setExtra('url', req.url);
				scope.setExtra('params', req.params);
				scope.setExtra('query', req.query);
				scope.setExtra('headers', req.headers);
			}

			if (res) {
				scope.setTag('ssr', true);
				scope.setExtra('statusCode', res.statusCode);

				// Running on the server, the response object is available.
				//
				// Next.js will pass an err on the server if a page's `getInitialProps`
				// threw or returned a Promise that rejected
				if (res.statusCode === 404) {
					// Opinionated: do not record an exception in Sentry for 404
					return resolve({ statusCode: 404 });
				}

				if (res.locals && res.locals.id) {
					scope.setExtra('id', res.locals.id);
				}

				if (err) {
					captureException(err);
					return resolve(errorInitialProps);
				}
			} else {
				// Running on the client (browser).
				//
				// Next.js will provide an err if:
				//
				//  - a page's `getInitialProps` threw or returned a Promise that rejected
				//  - an exception was thrown somewhere in the React lifecycle (render,
				//    componentDidMount, etc) that was caught by Next.js's React Error
				//    Boundary. Read more about what types of exceptions are caught by Error
				//    Boundaries: https://reactjs.org/docs/error-boundaries.html
				if (err) {
					scope.setTag('ssr', false);
					captureException(err);
					return resolve(errorInitialProps);
				}
			}

			// If this point is reached, getInitialProps was called without any
			// information about what the error might be. This is unexpected and may
			// indicate a bug introduced in Next.js, so record it in Sentry
			captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));

			return resolve(errorInitialProps);
		});
	});
};

export default CustomError;
