class User {
	constructor(repository) {
		this.repository = repository;
	}

	async list() {
		return this.repository.list();
	}

	async create(data) {
		return this.repository.create(data);
	}

	async update(id, data) {
		return this.repository.update(id, data);
	}

	async delete(id) {
		return this.repository.delete(id);
	}

	async validateOtp(code) {
		return this.repository.validateOtp(code);
	}

	async cancelOtp() {
		return this.repository.cancelOtp();
	}

	async changeTemporaryPassword(password) {
		return this.repository.changeTemporaryPassword(password);
	}
}

module.exports = User;
