import PropTypes from 'prop-types';
import styles from '../../../styles/basicVariables.module.css';
/**
 * This component renders a basic button and is the base for other button components (e.g. OutlinedButton or CTAbutton).
 * The Basic Button Component ist just the base component for various other button components as the outlined Button component or the CTA Button Component.
 * If rendered without any props it has the look of a CTA Button by default. Even if this Button works for most situations it is advised to use the other Button Components.
 */
const BasicButton = ({
	loading,
	disabled,
	ariaLabel,
	buttonClass,
	customClasses,
	shadow,
	iconClass,
	buttonLabel,
	fnOnClick,
	inlineSvgIcon
}) => {
	const isDisabled = disabled || loading;

	const handleClick = () => fnOnClick();

	const renderIconOrLoading = (isLoading, iconClass, inlineSvgIcon) => {
		if (isLoading) {
			return <img alt='Lädt...' src={'/res/img/ajax-loader.gif'} />;
		}

		if (inlineSvgIcon) {
			return inlineSvgIcon;
		}

		if (iconClass !== '') {
			return <i className={iconClass} />;
		}
		return '';
	};

	const disabledClass = isDisabled ? `${styles.disabled}` : '';

	return (
		<button
			aria-label={ariaLabel}
			className={[buttonClass, disabledClass, customClasses].join(' ')}
			onClick={(e) => {
				e.preventDefault();
				handleClick();
			}}
		>
			{renderIconOrLoading(loading, iconClass, inlineSvgIcon)}
			<span>{buttonLabel}</span>
		</button>
	);
};

BasicButton.propTypes = {
	/** Button Style */
	buttonClass: PropTypes.string,
	/** custom classes */
	customClasses: PropTypes.string,
	/** iconClass to show an icon next to the buttonLabel (e.g. 'icon-pencil') */
	iconClass: PropTypes.string,
	/** Button label */
	buttonLabel: PropTypes.string,
	/** Aria label for accessibility */
	ariaLabel: PropTypes.string,
	/** show button shadow on/off */
	shadow: PropTypes.bool,
	/** disable Button */
	disabled: PropTypes.bool,
	/** show loading indicator (disables button) */
	loading: PropTypes.bool,
	/** onClick function */
	fnOnClick: PropTypes.func,
	/** inline svg to be used as the button icon */
	inlineSvgIcon: PropTypes.node
};

BasicButton.defaultProps = {
	buttonClass: `${styles.btn} ${styles.weiter}`,
	customClasses: '',
	iconClass: '',
	buttonLabel: 'Jetzt vergleichen',
	loading: false,
	disabled: false,
	shadow: true,
	inlineSvgIcon: undefined,
	fnOnClick: () => {}
};

export default BasicButton;
