import fetch from 'isomorphic-unfetch';

class Bank {
	async list() {
		const response = await fetch('/api/banks');
		return response.json();
	}

	async create(data) {
		const response = await fetch('/api/banks', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}

	async update(id, data) {
		const response = await fetch('/api/banks/' + id, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}
}

module.exports = Bank;
