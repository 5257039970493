class Vorgang {
	constructor(repository) {
		this.repository = repository;
	}

	async list(conditions = {}) {
		return this.repository.list(conditions);
	}

	async findById(id) {
		return this.repository.findById(id);
	}

	async delete(id) {
		return this.repository.delete(id);
	}

	async create(item) {
		return this.repository.create(item);
	}

	async setInternalId(vorgangId, internalId) {
		return this.repository.setInternalId(vorgangId, internalId);
	}

	async assignToCurrentUser(id) {
		return this.repository.assignToCurrentUser(id);
	}

	async unAssign(id) {
		return this.repository.unAssign(id);
	}

	async fordereDokumenteNach(vorgangId, message) {
		return this.repository.fordereDokumenteNach(vorgangId, message);
	}

	async createKondition(vorgangId, kondition) {
		return this.repository.createKondition(vorgangId, kondition);
	}

	async denyWithAlternative(vorgangId, ablehnungsgrund, kondition) {
		return this.repository.denyWithAlternative(vorgangId, ablehnungsgrund, kondition);
	}

	async deny(vorgangId, ablehnungsgrund) {
		return this.repository.deny(vorgangId, ablehnungsgrund);
	}
}

module.exports = Vorgang;
