class Kondition {
	constructor(repository) {
		this.repository = repository;
	}

	async create(condition) {
		return this.repository.create(condition);
	}

	async delete(id) {
		return this.repository.delete(id);
	}

	async storeDokumente(id, dokumente) {
		return this.repository.storeDokumente(id, dokumente);
	}

	async ablehnen(konditionId, ablehnungsgrund) {
		return this.repository.ablehnen(konditionId, ablehnungsgrund);
	}

	async genehmigen(konditionId, auszahlungsDatum = null) {
		return this.repository.genehmigen(konditionId, auszahlungsDatum);
	}

	async bankAkzeptieren(konditionId) {
		return this.repository.bankAkzeptieren(konditionId);
	}

	async bankAblehnen(konditionId, ablehnungsGrund = null) {
		return this.repository.bankAblehnen(konditionId, ablehnungsGrund);
	}

	async auszahlen(
		konditionId,
		auszahlungsDatum = null,
		betrag = null,
		abweichendeKondition = false
	) {
		return this.repository.auszahlen(konditionId, auszahlungsDatum, betrag, abweichendeKondition);
	}

	async ruecktritt(konditionId, ruecktrittsgrund) {
		return this.repository.ruecktritt(konditionId, ruecktrittsgrund);
	}

	async kundenRuecktritt(konditionId) {
		return this.repository.kundenRuecktritt(konditionId);
	}
}

module.exports = Kondition;
