import BasicButton from '../basicButton/BasicButton.js';
import styles from '../../../styles/basicVariables.module.css';
/**
 * This component renders a green CTA button according to the DB default styles.
 */
//  buttonClass='btn weiter-button ripple'
const CTAButton = (props) => (
	<BasicButton buttonClass={`${styles.btn} ${styles.weiterButton}`} {...props} />
);

export default CTAButton;
