module.exports = ({ buildId, serverCfg } = {}) => {
	const isServer = typeof window === 'undefined';
	let sentryCfg = {};
	sentryCfg.release = buildId || process.env.BUILD_ID;
	if (isServer) {
		// server
		sentryCfg = Object.assign(sentryCfg, serverCfg || process.env.sentry.server);
		sentryCfg.beforeSend = (event, hint) => {
			if (process.env.CONFIG_DIR !== 'prod') {
				console.error(hint.originalException || hint.syntheticException || event);
				return null; // this drops the event and nothing will be sent to sentry
			}
			return event;
		};
	} else {
		sentryCfg = Object.assign(sentryCfg, process.env.sentry.client);
		sentryCfg.beforeSend = (event, hint) => {
			if (process.env.CONFIG_DIR !== 'prod') {
				console.error(hint.originalException || hint.syntheticException || event);
				return null; // this drops the event and nothing will be sent to sentry
			} else {
				event.extra = {};
				if (hint.originalException) {
					try {
						const keys = Object.keys(hint.originalException);
						if (keys.length) {
							event.extra.errorProperties = {};
							keys.forEach((key) => {
								event.extra.errorProperties[key] = hint.originalException[key];
							});
						}
					} catch (err) {
						console.warn('[sentry] Failed to assign enumerable error properties to extras', err);
					}
				}
				return event;
			}
		};
	}

	return sentryCfg;
};
