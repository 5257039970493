class Settings {
	constructor(repository) {
		this.repository = repository;
	}

	async get() {
		return this.repository.get();
	}

	async update(data) {
		if (data.warnungFrist) {
			data.warnungFrist = parseInt(data.warnungFrist, 10);
		}
		if (data.sessionLength) {
			data.sessionLength = parseInt(data.sessionLength, 10);
		}
		return this.repository.update(data);
	}
}

module.exports = Settings;
