class Bank {
	constructor(repository) {
		this.repository = repository;
	}

	async list() {
		return this.repository.list();
	}

	async create(data) {
		return this.repository.create(data);
	}

	async update(id, data) {
		return this.repository.update(id, data);
	}
}

module.exports = Bank;
