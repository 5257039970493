import React from 'react';
import CTAButton from '../buttons/ctaButton/CTAButton';
import styles from './errorPage.module.css';
const ErrorPage = React.memo(({ statusCode }) => {
	const errorMessages = {
		notFound: {
			headline: 'Oh, das hätte nicht passieren dürfen!',
			text: 'Die Seite wurde leider nicht gefunden, aber hier finden Sie bestimmt was Sie suchen:'
		},
		error: {
			headline: 'Oh, das hätte nicht passieren dürfen!',
			text: 'Es ist leider etwas schief gelaufen.'
		}
	};
	const getErrorText = function () {
		if (statusCode === 404) {
			return (
				<>
					<h1>{errorMessages.notFound.headline}</h1>
					<h4>{errorMessages.notFound.text}</h4>
				</>
			);
		} else {
			return (
				<>
					<h1>{errorMessages.error.headline}</h1>
					<h4>{errorMessages.error.text}</h4>
				</>
			);
		}
	};

	return (
		<div className={styles.errorPage}>
			<span className={styles.displayTextStatuscode}>{statusCode || '500'}</span>
			{getErrorText()}
			<CTAButton
				fnOnClick={() => {
					location.href = '/';
				}}
				customClasses={styles.backToStart}
				buttonLabel='Zurück zum Login'
			/>
		</div>
	);
});

export default ErrorPage;
