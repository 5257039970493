import fetch from 'isomorphic-unfetch';

class User {
	async list() {
		const response = await fetch('/api/users');
		return response.json();
	}

	async create(data) {
		const response = await fetch('/api/users', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}

	async update(id, data) {
		const response = await fetch('/api/users/' + id, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}

	async delete(id) {
		const response = await fetch('/api/users/' + id, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.json();
	}

	async validateOtp(code) {
		const response = await fetch('/validateOtp', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ code })
		});
		return response.json();
	}

	async cancelOtp() {
		const response = await fetch('/cancelOtp', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.json();
	}

	async changeTemporaryPassword(password) {
		const response = await fetch('/changeTemporaryPassword', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ password })
		});
		return response.json();
	}
}

module.exports = User;
