import React, { useState } from 'react';
import services from '../../lib/shared/services';
import { useForm } from 'react-hook-form';
import styles from '../../styles/basicVariables.module.css';
export default function ValidateOtp() {
	const [isInvalid, setIsInvalid] = useState(false);
	const { register, handleSubmit, errors } = useForm({
		mode: 'all'
	});

	const submitHandler = async (values) => {
		const response = await services().user.validateOtp(values.otp);

		if (response && response.isValid === true) {
			window.location.reload();
			return;
		}

		if (response && response.isValid === false) {
			setIsInvalid(true);
		}
	};

	const cancelHandler = async (e) => {
		e.preventDefault();

		await services().user.cancelOtp();
		window.location.reload();
	};

	return (
		<div className={styles.changePasswordForm}>
			<img src='/static/svgs/logo-durchblicker.svg' />
			<div>
				<form onSubmit={handleSubmit(submitHandler)}>
					<label htmlFor='otp'>Code:</label>
					<input
						id='otp'
						type='text'
						autoFocus
						name='otp'
						ref={register({
							required: true
						})}
					/>
					{errors.otp && <span className={styles.errorMessage}>{errors.otp.message}</span>}
					{isInvalid && <span className={styles.errorMessage}>Der Code ist ungültig.</span>}
					<button className={styles.btn}>Code prüfen</button>
				</form>
				<button className={`${styles.btn} ${styles.cancel}`} onClick={cancelHandler}>
					Abbrechen
				</button>
			</div>
		</div>
	);
}
