import fetch from 'isomorphic-unfetch';

class Vorgang {
	async list(conditions = {}) {
		let url = '/api/vorgaenge';

		if (conditions.status) {
			url += '?status=' + conditions.status;
		} else if (conditions.inProgress) {
			url += '?inProgress=true';
		} else if (conditions.isFinished) {
			url += '?isFinished=true';
		}

		const response = await fetch(url);
		return response.json();
	}

	async create(item) {
		// item.id = items.length + 1;
		// items.push(item);
		// return item;
	}

	async setInternalId(vorgangId, internalId) {
		const response = await fetch('/api/vorgaenge/' + vorgangId + '/internalId', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				internalId
			})
		});

		return response.json();
	}

	async assignToCurrentUser(id) {
		const response = await fetch(`/api/vorgaenge/${id}/assign`, {
			method: 'PUT'
		});
		return response.json();
	}

	async delete(id) {
		const response = await fetch(`/api/vorgaenge/${id}`, {
			method: 'DELETE'
		});
		return response.json();
	}

	async unAssign(id) {
		const response = await fetch(`/api/vorgaenge/${id}/unassign`, {
			method: 'PUT'
		});
		return response.json();
	}

	async fordereDokumenteNach(vorgangId, message) {
		const response = await fetch('/api/vorgaenge/' + vorgangId + '/fordereDokumenteNach', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				erforderlicheDokumente: message
			})
		});

		return response.json();
	}

	async createKondition(vorgangId, kondition) {
		const response = await fetch('/api/vorgaenge/' + vorgangId + '/konditionen', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(kondition)
		});

		return response.json();
	}

	async denyWithAlternative(vorgangId, ablehnungsgrund, kondition) {
		const response = await fetch('/api/vorgaenge/' + vorgangId + '/denyWithAlternative', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				vorgangId,
				ablehnungsgrund,
				abgelehnt: true,
				kondition
			})
		});

		return response.json();
	}

	async deny(vorgangId, ablehnungsgrund) {
		const response = await fetch('/api/vorgaenge/' + vorgangId + '/deny', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				vorgangId,
				ablehnungsgrund,
				abgelehnt: true
			})
		});

		return response.json();
	}
}

module.exports = Vorgang;
