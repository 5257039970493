import { useEffect, useContext, useState } from 'react';
import { useSSE } from 'react-hooks-sse';
import UserContext from '../UserContext';
import Toast from './Toast';

const ToastBar = () => {
	const { user } = useContext(UserContext);
	const [messages, setMessages] = useState([]);
	const state = useSSE('message');

	const handleMessage = (message) => {
		setMessages([].concat(messages, message));
	};

	useEffect(() => {
		if (!state?.type) {
			return;
		}
		handleMessage(state);
	}, [state]);

	const removeMessage = (idx) => {
		setMessages(messages.filter((elm, _idx) => _idx !== idx));
	};

	return messages.map((elm, idx) => (
		<Toast message={elm} onClose={() => removeMessage(idx)} key={idx} />
	));
};

export default ToastBar;
