import React, { useContext, useState } from 'react';
import UserContext from '../UserContext';
import services from '../../lib/shared/services';
import { useForm } from 'react-hook-form';
import { PASSWORD_PATTERN } from '../../lib/shared/utils/constants';
import styles from '../../styles/basicVariables.module.css';
export default function ChangePassword() {
	const { user } = useContext(UserContext);

	const { register, formState, handleSubmit, getValues, errors } = useForm({
		mode: 'all'
	});

	const submitHandler = async (values) => {
		const response = await services().user.changeTemporaryPassword(values.password);

		if (response) {
			window.location.reload();
		}
	};

	if (!user.isTemporaryPassword) {
		window.location.href = '/';
	}

	return (
		<div className={styles.changePasswordForm}>
			<img src='/static/svgs/logo-durchblicker.svg' />
			<div>
				<form onSubmit={handleSubmit(submitHandler)}>
					<label htmlFor='password'>Passwort:</label>
					<input
						id='password'
						type='password'
						name='password'
						ref={register({
							required: true,
							pattern: {
								value: PASSWORD_PATTERN,
								message:
									'Bitte mindestens 8 Zeichen, mindestens 1x Groß-, Kleinbuchstaben, Zahlen und Sonderzeichen'
							}
						})}
					/>
					{errors.password && (
						<span className={styles.errorMessage}>{errors.password.message}</span>
					)}
					<label htmlFor='repeatedPassword'>Passwort wiederholen:</label>
					<input
						id='repeatedPassword'
						type='password'
						name='repeatedPassword'
						ref={register({
							required: true,
							validate: {
								matchesPassword: (value) => {
									const { password } = getValues();
									return password === value || 'Die Passwörter stimmen nicht überein!';
								}
							}
						})}
					/>
					{errors.repeatedPassword && (
						<span className={styles.errorMessage}>{errors.repeatedPassword.message}</span>
					)}

					<button className={styles.btn}>Passwort ändern</button>
				</form>
			</div>
		</div>
	);
}
