const loadService = (name) => {
	const Service = require('./' + name);
	const Repository = require('../repositories/' + name);

	return new Service(new Repository());
};
const services = {
	user: loadService('user'),
	bank: loadService('bank'),
	vorgang: loadService('vorgang'),
	transaction: loadService('transaction'),
	kondition: loadService('kondition'),
	settings: loadService('settings')
};

module.exports = services;
