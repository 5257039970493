import fetch from 'isomorphic-unfetch';

class Settings {
	async get() {
		const response = await fetch('/api/settings');
		return response.json();
	}

	async update(data) {
		const response = await fetch('/api/settings', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}
}

module.exports = Settings;
