import fetch from 'isomorphic-unfetch';

class Kondition {
	async create(condition) {
		const url = '/api/konditionen';

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(condition)
		});
		return response.json();
	}

	async delete(id) {
		return fetch(`/api/konditionen/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			}
		});
	}

	async storeDokumente(id, dokumente) {
		const response = await fetch(`/api/konditionen/${id}/dokumente`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dokumente })
		});
		return response.json();
	}

	async bankAkzeptieren(id) {
		const response = await fetch(`/api/konditionen/${id}/bankAkzeptieren`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.json();
	}

	async bankAblehnen(id, ablehnungsGrund) {
		const response = await fetch(`/api/konditionen/${id}/bankAblehnen`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ ablehnungsGrund })
		});
		return response.json();
	}

	async genehmigen(konditionId, auszahlungsDatum = null) {
		let payload = null;

		if (auszahlungsDatum) {
			payload = {
				auszahlungsDatum
			};
		}
		const response = await fetch(`/api/konditionen/${konditionId}/genehmigen`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: payload ? JSON.stringify(payload) : null
		});

		return response.json();
	}

	async ablehnen(konditionId, ablehnungsgrund) {
		const response = await fetch(`/api/konditionen/${konditionId}/ablehnen`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				ablehnungsgrund
			})
		});

		return response.json();
	}

	async auszahlen(konditionId, auszahlungsDatum, auszahlungsBetrag, abweichendeKondition) {
		const response = await fetch(`/api/konditionen/${konditionId}/auszahlen`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				auszahlungsDatum,
				auszahlungsBetrag,
				abweichendeKondition
			})
		});

		return response.json();
	}

	async ruecktritt(konditionId, ruecktrittsgrund) {
		const response = await fetch(`/api/konditionen/${konditionId}/ruecktritt`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				ruecktrittsgrund
			})
		});

		return response.json();
	}

	async kundenRuecktritt(konditionId) {
		const response = await fetch(`/api/konditionen/${konditionId}/kundenRuecktritt`, {
			method: 'POST'
		});

		return response.json();
	}
}

module.exports = Kondition;
