class Transaction {
	constructor(repository) {
		this.repository = repository;
	}

	async get(id, bankId) {
		return this.repository.get(id, bankId);
	}
}

module.exports = Transaction;
