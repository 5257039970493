import styles from '../../styles/basicVariables.module.css';
const Toast = (props) => {
	let message = '';
	let link;

	switch (props.message.type) {
		case 'VORGANG_CREATED':
			message = 'Neuen Vorgang erhalten';
			link = `/vorgang/${props.message.id}`;
			break;
		case 'VORGANG_VERTRAG_GEWUENSCHT':
			message = 'Neuer Vorgang Vertrag gewünscht';
			link = `/vorgang/${props.message.id}`;
			break;
		case 'USER_ASSIGNED':
			message = 'Benutzer wurde zugewiesen';
			break;
		case 'KREDIT_BEANTRAGT':
			message = 'Kredit wurde beantragt';
			break;
		case 'DOKUMENTE_NACHGEREICHT':
			message = 'Dokumente wurden nachgereicht';
			link = `/vorgang/${props.message.id}`;
			break;
		default:
			message = `${props.message.type} - ${JSON.stringify(props.message)}`;
	}
	return (
		<div className={styles.notification}>
			{message} {link && <a href={link}>öffnen </a>}
			<button onClick={props.onClose}>x</button>{' '}
		</div>
	);
};

export default Toast;
